import VueApp from '../../../core/adapters/VueApp';
import {data, method} from '../../../core/adapters/VueComponent';
import MobileMixin from '../../../core/mixins/MobileMixin';

export default class PageSearch extends VueApp {
    static override mixins = [MobileMixin]

    @data()
    categories = Object.create(null);

    @data()
    material_filters = Object.create(null);

    @data()
    query: string;

    @data()
    show_filters: boolean;

    override beforeMount() {
        super.beforeMount();

        let url = new URL(window.location.href);

        this.query = url.searchParams.get('q');

        if (url.searchParams.get('category')) {
            let selected_categories = (url.searchParams.get('category') || '').split(',');

            for (const category of selected_categories) {
                this.categories[category] = true;
            }
        }

        if (url.searchParams.get('filter')) {
            let selected_material_filters = url.searchParams.get('filter').split(',');

            for (const filter of selected_material_filters) {
                this.material_filters[filter] = true;
            }
        }
    }

    override mounted() {
        super.mounted();
    }

    @method()
    search() {
        let url = new URL(window.location.origin + window.location.pathname);

        url.searchParams.set('q', this.query);

        let category_list = Object.keys(this.categories).filter(v => this.categories[v]);
        let filters_list = Object.keys(this.material_filters).filter(v => this.material_filters[v]);

        if (category_list.length > 0) {
            url.searchParams.set('category', category_list.join(','));
        }
        if (filters_list.length > 0) {
            url.searchParams.set('filter', filters_list.join(','));
        }

        window.location.href = url.href;
    }

    @method()
    toggleAdvancedFilters() {
        document.getElementById('advanced-filters').classList.toggle('desktop-only');
    }
}
