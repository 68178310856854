import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import MessageList from '../../../../core/utils/MessageList';
import IVueComponent from '../../../../core/adapters/IVueComponent';

import {html, LoginState} from './AccountLogin.html';
import {closeModal} from '../../../../core/utils/Modal';
import UserProfileService from '../../../userprofile/services/UserProfileService';
import {Services} from '../../../../core/services/Services';
import {processRequestError} from '../../../../core/utils/utils';
import {Http} from '../../../../core/services/Http';

class AccountLoginController extends VueComponent {

    @data()
    errors: MessageList = new MessageList();

    @data()
    successes: MessageList = new MessageList();

    @data()
    processing: boolean;

    @data()
    response: any = {};

    @data()
    state: LoginState = LoginState.LOGIN;

    @data()
    username_email: string;

    @data()
    password: string;

    @prop()
    modal: boolean;

    constructor(component) {
        super(component);

        this.resetStates();

        Services.get<UserProfileService>('UserProfileService').bind('reset-login-modal', () => {
            this.resetStates();
        }, this);
        Services.get<UserProfileService>('UserProfileService').bind('set-register-state', () => {
            this.state = LoginState.REGISTER;
            this.$forceUpdate();
        }, this);
    }

    override unmounted() {
        super.unmounted();

        Services.get<UserProfileService>('UserProfileService').unbind('reset-login-modal');
        Services.get<UserProfileService>('UserProfileService').unbind('set-register-state');
    }

    @method()
    resetMessages() {
        this.errors = new MessageList();
        this.successes = new MessageList();
    }

    @method()
    public resetStates() {
        this.state = LoginState.LOGIN;
        Services.get<UserProfileService>('UserProfileService').login_redirect = null;
        this.resetMessages();
    }

    @method()
    facebookEnabled(): boolean {
        return !!window['facebook_oauth_enabled'];
    }

    @method()
    googleEnabled(): boolean {
        return !!window['google_oauth_enabled'];
    }

    @method()
    appleEnabled() {
        return !!window['apple_oauth_enabled'];
    }

    @method()
    loginUrl(path) {
        let search_params = new URLSearchParams();
        search_params.set('return_url', window.location.pathname + window.location.search);
        return path + '?' + search_params.toString();
    }

    @method()
    public login($event?) {
        this.resetMessages();

        const form = new FormData()
        form.append('username', this.username_email);
        form.append('password', this.password);

        this.processing = true;
        Services.get<Http>('$http').request({
            data: form,
            url: '/account/api/login/',
            method: 'POST'
        }).then((response) => {
            this.processing = false;
            this.handleRedirect();
        }, (error) => {
            this.processing = false;
            this.handleResponseError(error);
        });
    }

    @method()
    public register($event?) {
        this.resetMessages();

        const form = new FormData()
        form.append('email', this.username_email);
        form.append('password', this.password);

        this.processing = true;
        Services.get<Http>('$http').request({
            data: form,
            url: '/account/api/register/',
            method: 'POST'
        }).then((response) => {
            this.processing = false;
            if (response.data.authenticated) {
                this.handleRedirect();
            }
            else {
                if (response.data.message) {
                    this.successes.add('email', response.data.message);
                }
            }
        }, (error) => {
            this.processing = false;
            this.handleResponseError(error);
        });
    }

    @method()
    public resetPassword($event?) {
        this.resetMessages();

        const form = new FormData()
        form.append('email', this.username_email);

        this.processing = true;
        Services.get<Http>('$http').request({
            data: form,
            url: '/account/api/reset-password/',
            method: 'POST'
        }).then((response) => {
            this.processing = false;
            if (response.data.message) {
                this.successes.add('email', response.data.message);
            }
        }, (error) => {
            this.processing = false;
            this.handleResponseError(error);
        });
    }


    @method()
    public emailLogin() {
        this.resetMessages();

        const form = new FormData()
        form.append('email', this.username_email);

        this.processing = true;
        Services.get<Http>('$http').request({
            data: form,
            url: '/account/api/request-login-email/',
            method: 'POST'
        }).then((response) => {
            this.processing = false;
            if (response.data.message) {
                this.successes.add('email', response.data.message);
            }
        }, (error) => {
            this.processing = false;
            this.handleResponseError(error);
        });
    }

    @method()
    close() {
        this.resetMessages();
        if (document.getElementById('loginModal')) {
            closeModal('loginModal');
        }
    }

    handleRedirect() {
        const next = new URL(window.location.href).searchParams.get('next') || Services.get<UserProfileService>('UserProfileService').login_redirect;

        if (!next && window.location.pathname.indexOf('/account/login/') != -1) {
            //Redirect to home if the user somehow ended up at /account/login/ without a redirect link
            window.location.pathname = '/';
        }

        if (next) {
            let url = new URL(window.location.origin + next);
            window.location.assign(url);
        }
        else if (window.location.pathname.includes('/account/login/')) {
            window.location.pathname = '/';
        }
        else if (window.location.pathname.includes('/checkout/account/')) {
            window.location.pathname = '/checkout/';
        }
        else {
            window.location.reload();
        }
    }

    handleResponseError(error) {
        if (error.response.status === 429) {
            this.errors.add('__all__', 'Too many attempts, please try again later.')
        }
        else {
            this.errors = processRequestError(error);
        }
        this.$forceUpdate();
        this.trigger('sync');
    }
}

export default function AccountLogin(): IVueComponent {

    return {
        controller: AccountLoginController,
        template: html,
        tag: 'account-login'
    };
}