let initialized = false;
export default function ToggleChatBinding() {
    if (initialized) {
        console.warn('Tried to initialize ToggleChatBinding more than once.')
        return;
    }
    initialized = true;

    for (const el of document.querySelectorAll('[data-toggle-chat]')) {
        el.addEventListener('click', () => {
            if (window['toggleChat']) {
                window['toggleChat']();
            }
        })
    }
}