import IVueDirective from '../../adapters/IVueDirective';
import tippy from 'tippy.js';

import Company from '../../services/Company';
import {Services} from '../../services/Services';
import {mobileScreenSize} from '../../utils/utils';

export default function Tooltip(): IVueDirective {
    return {
        directive: {
            beforeMount: function (el, binding, vnode) {
                // Tooltips don't work well on mobile so lets just disable them
                if (mobileScreenSize()) {
                    return;
                }

                if (!el.attributes['tooltip-content']) {
                    return;
                }

                const content = el.attributes['tooltip-content'].value
                let position = null;

                if(el.attributes['tooltip-position']) {
                    position = el.attributes['tooltip-position'].value
                }
                else {
                    position = 'top'
                }

                el['tippy_instance'] = tippy(el, {
                    content: content,
                    placement: position,
                    theme: 'custom',
                    animation: 'perspective',
                    delay: [350, 0]
                });
            },
            beforeUnmount: function (el: Element, binding, vnode) {
                if (el['tippy_instance']) {
                    el['tippy_instance'].unmount();
                }
            }
        },
        tag: 'tooltip'
    }
}