import {Collection} from '../Collection';
import {Model} from '../Model';
import {ForeignKeyField} from './ForeignKeyField';
import {ResourceURI} from '../helpers/ResourceURI';

/**
 * ForeignKeyURIField can be the object, resource_uri or pk of the foreign obj
 *
 * Object/T: You can use field.load() to load the object.
 * Resource URI: URI string is returned by default from TastyPie
 * Foreign PK: When POST|PUT data to the API, PKs can be used
 */

// Ideally the type would be T | number | string but due to how typescript works that would be a type that
// only contains the functions that all 3 of those types contain which would exclude all functions of T
export type TForeignKeyURI<T> = T | any;

export class ForeignKeyURIField<T extends Model> extends ForeignKeyField {
    public resourceUri: ResourceURI;
    public _autoSelectCollection: Collection<T>;

    // We dont want to auto load uris when they are allowed and expected
    protected override initialValueSetup(data): any {
        return data;
    }

    override set value(data: any) {
        if (data === null || data === undefined) {
            if (this.value === null || this.value === undefined)
                this.setValue(null);

            return;
        }

        if (typeof data === 'string') {
            const model: T = this.autoSelectCollection.getItemFromURI(data);
            if (model) {
                data = model;
            } else {
                this.resourceUri = new ResourceURI(data);
            }
        } else if (typeof data === 'number') {
            const model: T = this.autoSelectCollection.getItemFromID(data);
            if (model) {
                data = model;
            } else {
                this.resourceUri = new ResourceURI(this.relatedModel ? this.relatedModel.uri.replace(':id', data) : `/${data}/`);
            }
        }

        if (this.relatedModel && data instanceof this.relatedModel) {
            this.setValue(data);
        }
        else if (['string', 'number'].indexOf(typeof data) > -1) {
            this.setValue(data);
        }
        else {
            this.setValue(new this.relatedModel(data));
        }

        if (this.config.load)
            this.load();
    }

    override get value() {
        return this.getValue();
    }

    get id() {
        if (this.resourceUri) {
            return this.resourceUri.id;
        } else if (this.getValue() instanceof this.relatedModel) {
            return this.getValue().id;
        } else {
            return null;
        }
    }

    override getPostData() {
        // If the value is null, let's just return early
        if ([null, undefined].indexOf(this.value) > -1)
            return null;

        // Since this is a URI field, we should try to return a string URI
        if (typeof(this.value) === 'string')
            return this.value;

        else if (typeof(this.value) === 'object' && typeof(this.value.resource_uri || this.value.buildResourceURI()) === 'string')
            return this.value.resource_uri || this.value.buildResourceURI();

        // If a new object has been assigned, try to let parent class handle it
        return super.getPostData();
    }

    load() {
        if (this.id) {
            this.setValue(this.relatedModel.objects.get({
                id: this.id
            }));
        }

        return this.getValue();
    }

    /**
     * Setting a autoSelectCollection will try to find the model in the
     * collection when a string URI is passed to `set value` method above.
     *
     * @param collection
     */
    set autoSelectCollection(collection: Collection<T>) {
        this._autoSelectCollection = collection;

        // If the current value is a string, reset it to find the model
        if (typeof this.value == 'string') {
            this.value = this.value;
        }
    }

    get autoSelectCollection(): Collection<T> {
        if (!this._autoSelectCollection)
            this._autoSelectCollection = new Collection<T>();
        return this._autoSelectCollection;
    }
}
