import ErrorList from './ErrorList/ErrorList';
import VueApp from '../adapters/VueApp';
import GenericDropdown from './GenericDropdown/GenericDropdown';
import Paginator from './Paginator/Paginator';
import CallbackPaginator from './CallbackPaginator/CallbackPaginator';
import ColorDropdown from './ColorDropdown/ColorDropdown';
import MultiSelectionDropdown from './MultiSelectionDropdown/MultiSelectionDropdown';
import MessageListComponent from './MessageList/MessageList';
import FileUpload from './FileUpload/FileUpload';
import Size from './Size/Size';
import MeasurementOption from './MeasurementOption/MeasurementOption';
import FontDropdown from './FontDropdown/FontDropdown';
import TextElement from './TextElement/TextElement';


export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(ErrorList);
    vue_app.registerComponent(GenericDropdown);
    vue_app.registerComponent(Paginator);
    vue_app.registerComponent(CallbackPaginator);
    vue_app.registerComponent(ColorDropdown);
    vue_app.registerComponent(MultiSelectionDropdown);
    vue_app.registerComponent(MessageListComponent);
    vue_app.registerComponent(FileUpload);
    vue_app.registerComponent(Size);
    vue_app.registerComponent(MeasurementOption);
    vue_app.registerComponent(FontDropdown);
    vue_app.registerComponent(TextElement);
}