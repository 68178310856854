/*
    This duplicates the v-tooltip functionality but works outside of a vue application
 */
import tippy from 'tippy.js';
import {mobileScreenSize} from '../utils';

let initialized = false;
export default function TooltipBinding() {
    if (initialized) {
        console.warn('Tried to initialize TooltipBinding more than once.')
        return;
    }
    initialized = true;

    // Tooltips don't work well on mobile so lets just disable them
    if (mobileScreenSize()) {
        return;
    }

    for (const el of document.querySelectorAll('[data-tooltip]')) {
        const content = el.attributes['data-tooltip-content'].value
        let position = null;

        if(el.attributes['data-tooltip-position']) {
            position = el.attributes['data-tooltip-position'].value
        }
        else {
            position = 'top'
        }

        tippy(el, {
            content: content,
            placement: position,
            theme: 'custom',
            animation: 'perspective',
            delay: [500, 0]
        })
    }
}
