import IVueDirective from '../adapters/IVueDirective';

export default function FileHover(): IVueDirective {
    return {
        directive: {
            mounted: function (el: Element, binding, vnode) {
                // This solution kinda sucks but so does the dragenter/dragleave event listener.
                // It will call dragleave on every child element unless you specify pointer-events: none.
                // But that prevents buttons and any other interactable element from working.
                // So a simple solution is to keep track of the depth and only when the depth is 0 will the class be removed.
                let element_counter = 0;
                let window_counter = 0;

                let drag_enter = (event) => {
                    element_counter++;
                    if (event.target == el) {
                        el.classList.add('file-hover');
                    }
                }
                let drag_leave = (event) => {
                    element_counter--;
                    if (element_counter == 0) {
                        el.classList.remove('file-hover');
                    }
                }
                let window_drag_enter = (event) => {
                    window_counter++;
                    el.classList.add('file-dragging');
                }
                let window_drag_leave = (event) => {
                    window_counter--;
                    if (window_counter == 0) {
                        el.classList.remove('file-dragging');
                    }
                }
                let drag_end = (event) => {
                    window_counter = 0;
                    element_counter = 0;
                    el.classList.remove('file-dragging');
                    el.classList.remove('file-hover');
                }

                el.addEventListener('dragenter', drag_enter);
                el.addEventListener('dragleave', drag_leave);
                el.addEventListener('drop', drag_end);
                window.addEventListener('dragend', drag_end);
                window.addEventListener('dragenter', window_drag_enter);
                window.addEventListener('dragleave', window_drag_leave);

                el['_file_hover_cleanup'] = () => {
                    window.removeEventListener('dragleave', window_drag_leave);
                    window.removeEventListener('dragenter', window_drag_enter);
                    window.removeEventListener('dragend', drag_end);
                    el.removeEventListener('drop', drag_end);
                    el.removeEventListener('dragleave', drag_leave);
                    el.removeEventListener('dragenter', drag_enter)
                }
            },
            beforeUnmount: function (el: Element, binding, vnode) {
                if (el['_file_hover_cleanup']) {
                    el['_file_hover_cleanup']();
                }
            }
        },
        tag: 'file-hover'
    }
}
