export enum LoginState {
    LOGIN,
    REGISTER,
    EMAIL_LOGIN,
    RESET_PASSWORD,
}

export const html:string = `
<div class="account-login flex-center stretch column spacing-3xl">
    <a href="/">
        <picture class="flex-center">
            <source media="screen and (prefers-color-scheme: dark)" :srcset="$filters.static('img/logo-full-dark.svg')" v-if="$filters.showDarkModeOption()">
            <img :src="$filters.static('img/logo-full.svg')" loading="lazy" alt="Logo">
        </picture>
    </a>
    <div class="flex-center column stretch" v-if="state == ${LoginState.LOGIN}">
        <div class="flex-center column spacing-xs">
            <h1 class="align-center">Account Sign In</h1>
            <span class="flex-full-center text-sm spacing-xs align-center">Don't have an account? <button @click="state = ${LoginState.REGISTER}; resetMessages()" class="btn-link">Register</button></span>
        </div>
        <form class="flex-center column stretch" @submit.prevent="login">
            <div class="input-group">
                <label for="email">Email</label>
                <message-list :errors="errors" :successes="successes" field="username,email,__all__">
                    <input type="email" 
                            id="username" 
                            name="username" 
                            v-model="username_email" 
                            autocomplete="username email"  
                            placeholder="Enter your email" 
                            :disabled="processing" required>
                </message-list>
            </div>
            <div class="input-group">
                <label for="password">Password</label>
                <message-list :errors="errors" :successes="successes" field="password">
                    <div class="password-input" v-password-toggle>
                        <input  type="password" 
                                id="password" 
                                name="password" 
                                v-model="password" 
                                autocomplete="current-password" 
                                placeholder="Enter a password" 
                                :disabled="processing" required>
                                
                        <i class="password-toggle" aria-hidden="true"></i>
                    </div>
                </message-list>
                <div class="flex-right full-width text-sm">
                    <button type="button" class="btn-link" @click="state = ${LoginState.RESET_PASSWORD}; resetMessages()">Forgot Password</button>
                </div>
            </div>
            <div class="flex-center column stretch">
                <button class="btn-base btn-primary-action" :disabled="processing">Sign In</button>
            </div>
        </form>
        
        <div class="flex-center column stretch">
            <div class="flex-full-center no-wrap">
                <hr>
                OR
                <hr>
            </div>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/google/login/')" v-if="googleEnabled()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/google.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Google
            </a>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/apple/login/')" v-if="appleEnabled()">
                <img class="dark-invert" width="24px" height="24px" :src="$filters.static('img/icons/apple.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Apple
            </a>
            <button class="btn-base btn-secondary-action spacing-sm" @click="state = ${LoginState.EMAIL_LOGIN}; resetMessages()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/email.svg')" alt="Login With Email" loading="lazy"/>
                Sign in with Email
            </button>
        </div>
    </div>
    <div class="flex-center column stretch" v-if="state == ${LoginState.REGISTER}">
        <div class="flex-center column spacing-xs">
            <h1>Create an account</h1>
            <span class="flex-full-center text-sm spacing-xs">Already have an account? <button @click="state = ${LoginState.LOGIN}; resetMessages()" class="btn-link">Sign In</button></span>
        </div>
        <form class="flex-center column stretch" @submit.prevent="register">
            <div class="input-group">
                <label for="email">Email</label>
                <message-list :errors="errors" :successes="successes" field="username,email,__all__">
                    <input  type="email" 
                            id="username" 
                            name="username" 
                            v-model="username_email" 
                            autocomplete="username email"  
                            placeholder="Enter your email" 
                            :disabled="processing" required>
                </message-list>
            </div>
            <div class="input-group">
                <label for="password">Password</label>
                <message-list :errors="errors" :successes="successes" field="password">
                    <div class="password-input" v-password-toggle>
                        <input  type="password" 
                                id="password" 
                                name="password" 
                                v-model="password" 
                                autocomplete="current-password" 
                                placeholder="Enter a password" 
                                :disabled="processing" required>
                        <i class="password-toggle" aria-hidden="true"></i>
                    </div>
                </message-list>
            </div>
            <div class="flex-center column stretch">
                <button class="btn-base btn-primary-action" type="submit" :disabled="processing">Sign Up</button>
            </div>
        </form>
        
        <div class="flex-center column stretch">
            <div class="flex-full-center no-wrap">
                <hr>
                OR
                <hr>
            </div>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/google/login/')" v-if="googleEnabled()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/google.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Google
            </a>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/apple/login/')" v-if="appleEnabled()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/apple.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Apple
            </a>
        </div>
    </div>
    <div class="flex-center column stretch" v-if="state == ${LoginState.RESET_PASSWORD}">
        <div class="flex-center column spacing-xs">
            <h1>Reset password</h1>
            <span class="text-sm">Send an email link to reset your password.</span>
        </div>
        <form class="flex-center column stretch" @submit.prevent="resetPassword">
            <div class="input-group">
                <label for="email">Email</label>
                <message-list :errors="errors" :successes="successes" field="username,email,__all__">
                    <input  type="email" 
                            id="username" 
                            name="username" 
                            v-model="username_email" 
                            autocomplete="username email" 
                            placeholder="Enter your email" 
                            :disabled="processing" required>
                </message-list>
            </div>
            <div class="flex-center column stretch">
                <button class="btn-base btn-primary-action" type="submit" :disabled="processing">Reset Password</button>
            </div>
        </form>
        <div class="flex-center column stretch">
            <div class="flex-full-center no-wrap">
                <hr>
                OR
                <hr>
            </div>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/google/login/')" v-if="googleEnabled()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/google.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Google
            </a>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/apple/login/')" v-if="appleEnabled()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/apple.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Apple
            </a>
        </div>
        <span class="flex-full-center text-sm spacing-xs align-center">Remember your password? <button @click="state = ${LoginState.LOGIN}; resetMessages()" class="btn-link">Log in</button></span>
    </div>
    <div class="flex-center column stretch" v-if="state == ${LoginState.EMAIL_LOGIN}">
        <div class="flex-center column spacing-xs">
            <h1 class="align-center">Login with email</h1>
            <span class="text-sm">Login using your email.</span>
        </div>
        <form class="flex-center column stretch" @submit.prevent="emailLogin">
            <div class="input-group">
                <label for="email">Email</label>
                <message-list :errors="errors" :successes="successes" field="username,email,__all__">
                    <input  type="email" 
                            id="username" 
                            name="username" 
                            v-model="username_email" 
                            autocomplete="username email"  
                            placeholder="Enter your email" 
                            :disabled="processing" required>
                </message-list>
            </div>
            <div class="flex-center column stretch">
                <button class="btn-base btn-primary-action" type="submit" :disabled="processing">Send Login Email</button>
            </div>
        </form>
        <div class="flex-center column stretch">
            <div class="flex-full-center no-wrap">
                <hr>
                OR
                <hr>
            </div>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/google/login/')" v-if="googleEnabled()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/google.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Google
            </a>
            <a class="btn-base btn-secondary-action spacing-sm" :href="loginUrl('/account/apple/login/')" v-if="appleEnabled()">
                <img width="24px" height="24px" :src="$filters.static('img/icons/apple.svg')" alt="Login With Google" loading="lazy"/>
                Sign in with Apple
            </a>
        </div>
        <span class="flex-center text-sm spacing-xs">Have a password? <button @click="state = ${LoginState.LOGIN}; resetMessages()" class="btn-link">Log in</button></span>
    </div>
</div>
`;