export const html:string = `
<div id="file-paste-zone" class="file-upload-zone flex-center column no-wrap align-center spacing-xs" @dragover.prevent="" @drop.prevent="drop" v-if="!uploadFile()" @paste.prevent="paste" v-file-hover>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 16.2422C2.79401 15.435 2 14.0602 2 12.5C2 10.1564 3.79151 8.23129 6.07974 8.01937C6.54781 5.17213 9.02024 3 12 3C14.9798 3 17.4522 5.17213 17.9203 8.01937C20.2085 8.23129 22 10.1564 22 12.5C22 14.0602 21.206 15.435 20 16.2422M8 16L12 12M12 12L16 16M12 12V21" stroke="var(--brand-secondary, #6941C6)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <p class="desktop-only strong"> Drag file here to upload <br> or </p>
    <div class="input-group">
        <input class="hidden" :id="uuid" type="file" @change="drop" :accept="fileAcceptList()">
        <label class="btn-base btn-primary-action" :class="delayedSubmission || secondary ? 'btn-secondary-action' : 'btn-primary-action'" :for="uuid" v-show="!uploading">Choose File</label>
        <span class="text-sm" v-if="!allFiles">({{ fileTypeDisplay() }})</span>
    </div>
</div>
<div class="file-upload-zone" v-if="uploadFile() && !upload_failed">
    <div class="flex-left spacing-sm no-wrap">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="no-shrink">
            <path d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="flex-left column spacing-sm full-width">
            <div class="flex-left no-wrap full-width">
                <div class="flex-left column spacing-xs text-sm full-width">
                    <b>{{ uploadFile().name }}</b>
                    <span>{{ fileSize() }}</span>
                </div>
                <button class="btn-icon no-shrink" type="button" @click="cancelUpload()" v-if="!upload_success">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="upload_success">
                    <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#17B26A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="flex-left-center no-wrap full-width" v-if="uploading">
                <progress max="100" :value="percent_loaded">{{ percent_loaded }}</progress>
                <span class="text-sm">{{ $filters.number(percent_loaded, 0) }}%</span>
            </div>
            <span v-if="uploading && percent_loaded == 100">Processing File...</span>
        </div>
    </div>
</div>
<div class="file-upload-zone upload-failure" v-if="uploadFile() && upload_failed">
    <div class="flex-left spacing-sm no-wrap">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="no-shrink file-icon">
            <path d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="flex-left column spacing-sm full-width">
            <div class="flex-left no-wrap full-width">
                <div class="flex-left column spacing-xs text-sm full-width">
                    <b>{{ uploadFile().name }}</b>
                    <span class="text-error">Upload failed, please try again</span>
                </div>
                <button class="btn-icon no-shrink" type="button" @click="resetState()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <button class="btn-link" @click="resetState()">Try again</button>
        </div>
    </div>
</div>
`;