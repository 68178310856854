import {ChatState} from '../../services/BaseChatService';

export const html:string = `
<div class="chat-header flex-left-center padded-card spacing-sm space-between" v-show="chat.state != ${ChatState.IN_CHAT}">
    <div class="flex-left-center spacing-sm">
        <img height="50px" width="50px" class="chat-header-icon" src="/static/img/chat/site-chat-logo.svg">
        <h4>Chat with us!</h4>
    </div>
    <button class="btn-icon touch-spacing" @click="chat.minimized = !chat.minimized">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</div>
<div class="chat-header flex-left-center padded-card spacing-sm space-between" v-show="chat.state == ${ChatState.IN_CHAT}">
    <div class="flex-left-center no-wrap spacing-sm">
        <div class="image-portal">
            <img height="50px" width="50px" :class="{'default-image': !(chat.chat_room && chat.chat_room.customer_service_image) ? '4px': ''}" v-bind:src="chat.chat_room && chat.chat_room.customer_service_image || '/static/img/chat/site-chat-logo.svg'">
        </div>
        <h3>
            {{ chat.chat_room && chat.chat_room.customer_service_name ? chat.chat_room.customer_service_name : 'Customer Service' }}
        </h3>
    </div>
    <button class="btn-icon touch-spacing" @click="chat.minimized = !chat.minimized">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</div>
`;