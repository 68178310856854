/*
    This has similar functionality to the v-dropdown however it doesn't require Vue to avoid some issues.
    When vue mounts to a dom element, it will load in all images with loading="lazy" despite not being needed,
    which happens in the footer.

    This will not play well with Vue so do not use it within a Vue mounted area.
 */

let initialized = false;
export default function DropdownBinding() {
    if (initialized) {
        console.warn('Tried to initialize DropdownBinding more than once.')
        return;
    }
    initialized = true;

    for (const el of document.querySelectorAll('[data-dropdown]')) {
        const root = el;
        const button = el.children[0];
        const list = el.children[1];
        const config = el.attributes['data-dropdown'].value;

        let outside = config == 'outside';

        let dropdownOnButtonClickEvent = (event) => {
            // This prevents the click outside event from firing after this event and removing the open class we just added
            if (!outside) {
                event.stopImmediatePropagation();
            }
            root.classList.toggle('open');
            if (root.classList.contains('open')) {
                (list.querySelector('.selected') as any)?.focus();
            }
        }

        if (!outside) {
            let clickOutsideEvent = (event) => {
                event.preventDefault();
                if (event instanceof MouseEvent || (window['TouchEvent'] && event instanceof TouchEvent)) {
                    if (root.classList.contains('open')) {
                        root.classList.remove('open');
                        event.stopImmediatePropagation();
                    }
                }
            };
            button.addEventListener('click', clickOutsideEvent);
        }
        else {
            let clickOutsideEvent = (event) => {
                // Prevent space bar from activating the button
                if (event instanceof MouseEvent || (window['TouchEvent'] && event instanceof TouchEvent)) {
                    if (root.classList.contains('open')) {
                        // Clicks outside of the dropdown
                        if (!(root == event.target || root.contains(event.target as Node))) {
                            root.classList.remove('open');
                        }
                        // Clicks within the dropdown
                        else if (root != event.target && root.children[1].contains(event.target as Node)) {
                            root.classList.remove('open');
                        }
                    }
                }
            };
            document.body.addEventListener('click', clickOutsideEvent);
        }

        button.addEventListener('click', dropdownOnButtonClickEvent);
    }
}