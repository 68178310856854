import {IPromise, SimplePromise} from './SimplePromise';
import {createApp} from 'vue';
import MessageList from './MessageList';
import {AxiosError} from 'axios';

export function getInnerHeight(el): number {
    if (!el) {
        return 0;
    }
    let v = parseInt(window.getComputedStyle(el).getPropertyValue('height'));
    return isNaN(v) ? 0 : v;
}

export function getInnerWidth(el): number {
    if (!el) {
        return 0;
    }
    let v = parseInt(window.getComputedStyle(el).getPropertyValue('width'));
    return isNaN(v) ? 0 : v;
}

export function eventToFiles(event): File[] {
    let files = [];
    if (event.dataTransfer) {
        if (event.dataTransfer.items) {
            for (const file of event.dataTransfer.items) {
                files.push(file.getAsFile());
            }
        } else if (event.dataTransfer.files) {
            for (const file of event.dataTransfer.files) {
                files.push(file);
            }
        }
    }
    else if (event.target && event.target.files) {
        for (const file of event.target.files) {
            files.push(file);
        }
    }
    return files;
}


export function httpParamSerializer(data) {
    return Object.keys(data).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])).join('&');
}

export function copyToClipboard(text) {
    // @ts-ignore
    navigator.permissions.query({name: "clipboard-write"}).then((result) => {
        if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard.writeText(text);
        }
        else {
            console.error('Copy to clipboard permission not granted.')
        }
    }).catch((error) => {
        navigator.clipboard.writeText(text);
    });
}

export function isEmptyString(v) {
    if (typeof v == 'string') {
        if (v.trim() == '') {
            return true;
        }
    }
    return false;
}

/*
    Clamp a number from user input based on a defined precision.

    This must return the input (v) if its a empty string so that if a user clears the input field it won't
    revert to 0 and prevent them from typing.
 */
export function clamp(v, precision=2): any {
    if (isEmptyString(v)) {
        return v;
    }
    return parseFloat(Number(v).toFixed(2));
}

export function getClosestQuarter(length) {
    if (length % 1 > .87) {
        return Math.ceil(length);
    }
    else if (.87 >= length % 1 && length % 1 > .62) {
        return Math.floor(length) + .75;
    }
    else if (.62 >= length % 1 && length % 1 > .37) {
        return Math.floor(length) + .5;
    }
    else if (.37 >= length % 1 && length % 1 > .12) {
        return Math.floor(length) + .25;
    }
    else if (.12 >= length % 1) {
        return Math.floor(length);
    }
}

export function fitToArea(width, height, area, max_area= null) {
    if (max_area == null) {
        max_area = area;
    }

    const sqrt = Math.sqrt(width * height / area);
    let w = width / sqrt;
    let h = height / sqrt;
    w = Number(w.toFixed(3));
    h = Number(h.toFixed(3));

    if (w * h > max_area) {
        return fitToArea(width, height, area - 0.1);
    }

    return {width: w, height: h};
}

export function fitInArea(areaW, areaH, w, h) {
    if (w < h) {
        w *= areaH / h;
        h = areaH;
        if (w > areaW) {
            h *= areaW / w;
            w = areaW;
        }
    } else /*if (w > h)*/ {
        h *= areaW / w;
        w = areaW;
        if (h > areaH) {
            w *= areaH / h;
            h = areaH;
        }
    }
    return [w, h];
}

export function getOffset(element) {
    if (!element) {
        return;
    }

    let rect = element.getBoundingClientRect();

    // Make sure element is not hidden (display: none) or disconnected
    if ( rect.width || rect.height || element.getClientRects().length ) {
        let doc = element.ownerDocument;
        let docElem = doc.documentElement;

        return {
            top: rect.top + window.pageYOffset - docElem.clientTop,
            left: rect.left + window.pageXOffset - docElem.clientLeft
        };
    }
}

export function wait(time): IPromise<void> {
    let deferred = SimplePromise.defer<void>();

    setTimeout(() => {
        deferred.resolve()
    }, time);

    return deferred.promise;
}

export function kebabize(str: string) {
   return str.split('').map((letter, idx) => {
     return letter.toUpperCase() === letter
      ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
      : letter;
   }).join('');
}

export function renderTemplateText(template: string, context: object) {
    // We want to use the vue templating engine for generating descriptions based on the model so
    // we have to mount a vue app to a div, let it render, and then grab the text and destroy the dom node.
    if (!template) {
        return '';
    }

    // New lines are removed so we need to replace them with some placeholder so we can add them back in later
    template = template.split('\n').join('|||nl|||');

    context = {...context}
    let text_content = '';

    try {
        let app = createApp({
            data() {
                return context;
            }
        })

        let mounting_div = document.createElement('div');
        mounting_div.innerText = template;
        app.mount(mounting_div);

        text_content = mounting_div.innerText;
        text_content = text_content.split('|||nl|||').join('\n');

        app.unmount()
        mounting_div.remove();
    }
    catch (e) {
        console.error('Failed to render template.\n', {template: template, context: context});
        console.error(e);
    }

    return text_content;
}

export function processRequestError(error, field?: string): MessageList {
    if (!field) {
        field = '__all__'
    }

    if (error && error.response && error.response.data) {
        if (error.response.data['errors']) {
            return new MessageList(error.response.data['errors']);
        }
        if (error.response.data['error']) {
            return new MessageList({
                [field]: [error.response.data['error'],]
            })
        }
    }

    let list = new MessageList({
        [field]: ['An unknown error as occurred',]
    })

    if (error.code == '403') {
        list.add(field, '403 - Unauthorized.');
    }
    else if (error.code == '408') {
        list.add(field, '408 - Request timeout.');
    }
    else if (error.code == '413') {
        list.add(field, '413 - Request too large.');
    }
    else if (error.code == '414') {
        list.add(field, '414 - Request payload too long.');
    }
    else if (error.code == '429') {
        list.add(field, '429 - Too many requests. Please try again later.');
    }
    else if (error.code == '502') {
        list.add(field, '502 - Bad Gateway. Please try again later.');
    }
    else if (error.code == '503') {
        list.add(field, '503 - Service unavailable. Please try again later.');
    }
    else if (error.code == '504') {
        list.add(field, '504 - Gateway timeout. Please try again later.');
    }
    else {
        list.add(field, `Network error code ${error.code}`);
    }

    return list;
}

export function isInViewport(element) {
    if (!element) {
        return false;
    }

    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        Math.floor(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
        Math.floor(rect.right) <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function removeAll(item: string, ...keys: string[]): string {
    for (const key of keys) {
        item = item.split(key).join('');
    }

    return item;
}

export function toFixedFloor(value: number, digits=2) {
    let shift = Math.pow(10, digits);
    return Math.floor(value * shift) / shift;
}

export function daysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
}

// Given any string return a number, ex: 'test' => 3556498
export function hashCode(s: string) {
    return s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0);
}

export function userPrefersDarkMode(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export function isDarkModeSet() {
    if (document.body.classList.contains('light')) {
        return false;
    }
    if (document.body.classList.contains('dark')) {
        return true;
    }
    return userPrefersDarkMode();
}

export function mobileScreenSize(): boolean {
    return window.matchMedia('(max-width: 767.99999px)').matches;
}

export function xsScreenSize(): boolean {
    return window.matchMedia('(max-width: 479.99999px)').matches;
}

export function yieldThread(): Promise<void> {
    if ("scheduler" in window && "yield" in (window.scheduler as any)) {
        return (window.scheduler as any).yield();
    }

    // Fall back to setTimeout:
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}

export function lockScreenOrientation(orientation: 'portrait'|'landscape') {
    // This throws NotSupportedError on non-mobile devices if it's available
    try {
        if ('screen' in window && 'orientation' in screen.orientation) {
            if ('lock' in screen.orientation) {
                // Newer devices
                (screen.orientation as any).lock('portrait');
            } else if ('lockOrientation' in screen.orientation) {
                // Older devices
                (screen.orientation as any).lockOrientation('portrait');
            }
        }
    } catch (e) {
    }
}

export function lerp(x: number, y: number, a: number) {
    return x * (1 - a) + y * a
}

export function damp(x: number, y: number, rate: number, delta_time: number) {
    // Framerate independent lerp that moves from y to x at a given rate per second
    return lerp(x, y, 1 - Math.pow(rate, delta_time));
}