import {ChatState} from '../../services/BaseChatService';

export const html:string = `
<div class="chat-component card shadow-xl" v-on:dragover="chat.in_upload_state = true">
    <div class="chat-container flex-left column stretch no-wrap full-height spacing-none">
        <audio id="new-message" style="display: none">
            <source src="/static/audio/new-message.mp3" type="audio/mpeg">
            <source src="/static/audio/new-message.ogg" type="audio/ogg">
        </audio>
        
        <chat-header :chat="chat"></chat-header>
        
        <div class="padded-card flex-full-center column" v-if="chat.state == ${ChatState.LOADING} || chat.state == ${ChatState.WAITING_FOR_REP}">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="spin" style="animation-timing-function: steps(16, end);">
                <path d="M3 27C4.65685 27 6 25.6569 6 24C6 22.3431 4.65685 21 3 21C1.34315 21 0 22.3431 0 24C0 25.6569 1.34315 27 3 27Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.94" d="M4.59998 35C6.25683 35 7.59998 33.6569 7.59998 32C7.59998 30.3431 6.25683 29 4.59998 29C2.94312 29 1.59998 30.3431 1.59998 32C1.59998 33.6569 2.94312 35 4.59998 35Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.88" d="M9.20001 41.8C10.8569 41.8 12.2 40.4568 12.2 38.8C12.2 37.1431 10.8569 35.8 9.20001 35.8C7.54316 35.8 6.20001 37.1431 6.20001 38.8C6.20001 40.4568 7.54316 41.8 9.20001 41.8Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.81" d="M16 46.4C17.6569 46.4 19 45.0568 19 43.4C19 41.7431 17.6569 40.4 16 40.4C14.3431 40.4 13 41.7431 13 43.4C13 45.0568 14.3431 46.4 16 46.4Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.74" d="M24 48C25.6569 48 27 46.6569 27 45C27 43.3431 25.6569 42 24 42C22.3431 42 21 43.3431 21 45C21 46.6569 22.3431 48 24 48Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.68" d="M32 46.4C33.6569 46.4 35 45.0568 35 43.4C35 41.7431 33.6569 40.4 32 40.4C30.3431 40.4 29 41.7431 29 43.4C29 45.0568 30.3431 46.4 32 46.4Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.62" d="M38.8 41.8C40.4568 41.8 41.8 40.4568 41.8 38.8C41.8 37.1431 40.4568 35.8 38.8 35.8C37.1431 35.8 35.8 37.1431 35.8 38.8C35.8 40.4568 37.1431 41.8 38.8 41.8Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.55" d="M43.4 35C45.0569 35 46.4 33.6569 46.4 32C46.4 30.3431 45.0569 29 43.4 29C41.7432 29 40.4 30.3431 40.4 32C40.4 33.6569 41.7432 35 43.4 35Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.48" d="M45 27C46.6569 27 48 25.6569 48 24C48 22.3431 46.6569 21 45 21C43.3431 21 42 22.3431 42 24C42 25.6569 43.3431 27 45 27Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.42" d="M43.4 19C45.0569 19 46.4 17.6569 46.4 16C46.4 14.3431 45.0569 13 43.4 13C41.7432 13 40.4 14.3431 40.4 16C40.4 17.6569 41.7432 19 43.4 19Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.29" d="M38.8 12.2C40.4568 12.2 41.8 10.8569 41.8 9.20001C41.8 7.54316 40.4568 6.20001 38.8 6.20001C37.1431 6.20001 35.8 7.54316 35.8 9.20001C35.8 10.8569 37.1431 12.2 38.8 12.2Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.22" d="M32 7.60001C33.6569 7.60001 35 6.25686 35 4.60001C35 2.94315 33.6569 1.60001 32 1.60001C30.3431 1.60001 29 2.94315 29 4.60001C29 6.25686 30.3431 7.60001 32 7.60001Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.16" d="M24 6C25.6569 6 27 4.65685 27 3C27 1.34315 25.6569 0 24 0C22.3431 0 21 1.34315 21 3C21 4.65685 22.3431 6 24 6Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.1" d="M16 7.60001C17.6569 7.60001 19 6.25686 19 4.60001C19 2.94315 17.6569 1.60001 16 1.60001C14.3431 1.60001 13 2.94315 13 4.60001C13 6.25686 14.3431 7.60001 16 7.60001Z" fill="var(--brand-alt, #9E77ED)"/>
                <path opacity="0.04" d="M9.20001 12.2C10.8569 12.2 12.2 10.8569 12.2 9.20001C12.2 7.54316 10.8569 6.20001 9.20001 6.20001C7.54316 6.20001 6.20001 7.54316 6.20001 9.20001C6.20001 10.8569 7.54316 12.2 9.20001 12.2Z" fill="var(--brand-alt, #9E77ED)"/>
            </svg>

            <p class="align-center" v-if="chat.state == ${ChatState.LOADING}">
                Connecting
            </p>
            <p class="align-center" v-if="chat.state == ${ChatState.WAITING_FOR_REP}">
                Just a moment - We’re connecting you to one of our service reps...
            </p>
        </div>
        
        <div class="padded-card flex-center column stretch full-height space-between no-wrap" v-if="chat.state == ${ChatState.WAITING_TIMEOUT}">
            <div class="flex-center stretch align-center">
                <p>
                    We are currently working with other 
                    customers or away from our desk.
                </p>
                <p>
                    What would you like to do?
                </p>
            </div>
            <div class="flex-center stretch no-wrap">
                <button class="btn-base btn-secondary-action full-width" @click="chat.ok_waiting = true">
                    <i class="fa fa-clock"></i> Continue Waiting
                </button>
                <button class="btn-base btn-primary-action full-width" @click="chat.leave_chat()">
                    <i class="fa fa-envelope"></i> Leave Message
                </button>
            </div>
        </div>
        
        <div class="padded-card flex-center column stretch space-between no-wrap full-height" v-if="chat.state == ${ChatState.OFFLINE_MESSAGE_SENT}">
            <div class="flex-center align-center column">
                <h3 class="strong">Message Sent</h3>
                <p class="align-center">
                    We will get back to you as soon as we can. 
                    <br>
                    Thank you for your patience.
                </p>
            </div>
            <button class="btn-base btn-primary-action" @click="chat.close_chat()">
                Close Chat
            </button>
        </div>
        
        <div class="padded-card flex-center stretch align-center no-wrap" v-if="chat.state == ${ChatState.RECONNECTING}">
            <i class="fa fa-spinner fa-spin"></i>
            <h4 class="strong">Reconnecting...</h4>
        </div>
        
        <div class="padded-card flex-center column nowrap stretch space-between full-height no-wrap" v-if="chat.state == ${ChatState.DISCONNECTED}">
            <div class="flex-center stretch align-center">
                <div class="flex-left-center spacing-sm">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99975 7.49999V10.8333M9.99975 14.1667H10.0081M8.84585 3.24309L1.99178 15.0819C1.61161 15.7386 1.42153 16.0669 1.44962 16.3364C1.47412 16.5714 1.59727 16.785 1.7884 16.924C2.00753 17.0833 2.38691 17.0833 3.14568 17.0833H16.8538C17.6126 17.0833 17.992 17.0833 18.2111 16.924C18.4022 16.785 18.5254 16.5714 18.5499 16.3364C18.578 16.0669 18.3879 15.7386 18.0077 15.0819L11.1537 3.24309C10.7748 2.58878 10.5854 2.26163 10.3383 2.15176C10.1228 2.05591 9.87672 2.05591 9.66117 2.15176C9.41406 2.26163 9.22466 2.58879 8.84585 3.24309Z" stroke="var(--warning-weak, #F79009)" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <h4 class="text-warning">Uh-oh! Disconnected</h4>
                </div>
                <p>Looks like we lost connection for a moment. No worries—just click below to reconnect!</p>
            </div>
            <button class="btn-base btn-primary-action full-width" @click="chat.reconnect()">Reconnect</button>
        </div>
        
        <div class="padded-card flex-center align-center column space-between stretch full-height no-wrap" v-if="chat.state == ${ChatState.CHAT_CLOSED}">
            <div class="flex-center align-center column">
                <h4 class="strong">Thanks for chatting with us!</h4>
                <p>If you need anything else, we’re here to help.</p>
            </div>
            <button class="btn-base btn-primary-action" @click="chat.close_chat()">
                Close Chat
            </button>
        </div>
        
        <div class="padded-card column space-between stretch flex-center full-height no-wrap" v-if="chat.state == ${ChatState.OUT_OF_OFFICE}">
            <div class="flex-center stretch align-center">
                <p>
                    We are currently out of the office.
                </p>
                <p>
                    Our business hours are:
                    <br>
                    Monday - Friday
                    <br>
                    7:30am - 4:00pm PST
                </p>
                <p>
                    Please leave us a message and we will
                    <br>
                    get back to you the following business day.
                </p>
            </div>
            <div class="flex-center">
                <button class="btn-base btn-primary-action full-width no-wrap" @click="chat.leaving_message = true">
                    Leave Message
                </button>
            </div>
        </div>
        
        <div class="padded-card flex-center column stretch space-between no-wrap full-height" v-if="chat.state == ${ChatState.COLLECTING_INFO}">
            <div class="flex-left">
                <div class="input-group">
                    <label>Your name</label>
                    <message-list :errors="chat.error_list" :field="'customer-name'">
                        <input v-model="username" type="text">
                    </message-list>
                </div>
                <div class="input-group">
                    <label>Your email</label>
                    <message-list :errors="chat.error_list" :field="'email'">
                        <input v-model="email" type="email">
                    </message-list>
                </div>
                <div class="input-group">
                    <label>What can we help you with?</label>
                    <message-list :errors="chat.error_list" :field="'description'">
                        <textarea v-model="description"></textarea>
                    </message-list>
                </div>
            </div>
            
            <button class="btn-base btn-primary-action" @click="chat.updateChatRoom(username, email, description)">Chat now</button>
        </div>
        
        <div class="chat-message-container-content flex-center stretch column full-height space-between no-wrap spacing-none" v-if="chat.state == ${ChatState.IN_CHAT} || chat.state == ${ChatState.CLOSING}" id="chat-component">
            <div class="flex-full-center full-width padded-card" v-if="chat.in_upload_state">
                <div class="flex-left space-between full-width">
                    <h4>Upload a file</h4>
                    <button class="btn-icon touch-spacing" @click="chat.in_upload_state = null">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 5L5 15M5 5L15 15" stroke="var(--icon-strong, #98A2B3)" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                
                <file-upload url="/chat/api/upload-file/" :form-data="file_to_form_data" :success="upload_success" :failure="upload_failure"></file-upload>
            </div>
            
            <div id="chat-scroll-area" class="flex-left full-width padded-card chat-area grow" v-show="!chat.in_upload_state">
                <div v-for="(item, $index) in chat.history" class="chat-message full-width" :class="{ 'owned-by-user': isSelf(item), 'owned-by-staff': !isSelf(item), 'group-message': canGroup(item, $index), 'full-width': item.type == 'chat-event' }">                     
                    <a class="hover-actions" v-if="item.type == 'chat-file' && item.download_ur" :href="item.download_url" target="_blank">
                        <i class="fa fa-download" aria-hidden="true"></i> 
                    </a>
                    
                    <div v-if="item.type == 'chat-line'" class="flex-left column full-width stretch spacing-xs">
                        <div class="flex-left-end space-between">
                            <span class="text-sm">{{ item.customer_name }}</span>
                            <span class="text-xs">{{ $filters.date(item.date_created, 'HH:mm') }}</span>
                        </div>
                        <div class="chat-bubble">
                            <span class="chat-text">{{ item.message }}</span>
                        </div>
                    </div>
                    <div class="chat-event" v-if="item.type == 'chat-event'">
                        <div class="chat-note" v-if="item.event_slug == 'chat-note'">
                            <label>Staff Note:</label>
                            <p>{{ item.event_data }}</p>
                        </div>
                        <div v-if="item.event_slug != 'chat-note'" class="text-sm">
                            <span v-if="item.display_message">
                                {{ item.display_message }}
                            </span>
                            <span v-if="!item.display_message">
                                {{ item.event_data }} {{ item.link_display }} {{ item.event_name }}
                            </span>
                        </div>  
                    </div>
                    <div class="chat-file" v-if="item.type == 'chat-file'">
                        <div class="flex-left-end space-between">
                            <span class="text-sm">{{ item.customer_name }}</span>
                            <span class="text-xs">{{ $filters.date(item.date_created, 'HH:mm') }}</span>
                        </div>
                        <div class="chat-bubble">
                            <span class="text-xs">{{ item.name }}</span>
                            <img :src="item.preview" :alt="item.name">
                        </div>
                    </div>
                </div>
                <div class="typing-event" v-for="person in chat.users_typing">
                    {{ person.username }} is typing...
                </div>
            </div>
            <chat-input-area v-show="!chat.in_upload_state" :chat="chat"></chat-input-area>
            <div class="flex-center column no-wrap stretch padded-card-md" v-show="chat.state == ${ChatState.CLOSING}">
                <div class="align-center">
                    Are you sure you would like to end this chat?
                </div>
                <div class="flex-center stretch no-wrap">
                    <button class="btn-base btn-secondary-action full-width" @click="chat.attempting_to_close = false">
                        Cancel
                    </button>
                    <button class="btn-base btn-primary-action full-width" @click="chat.leave_chat(); chat.close_chat()">
                        End Chat
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
`;